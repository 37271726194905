<template>
  <div class="h-40 sm:h-full w-full flex justify-center items-center">
    <div class="flex flex-col sm:flex-row h-20 px-4">
      <div class="text-main-blue text-6xl font-bold">404</div>
      <div
        class="
          flex flex-col
          sm:border-l
          text-center
          sm:text-left
          sm:ml-4
          sm:pl-4
        "
      >
        <div class="font-bold text-4xl">Página no encontrada</div>
        <div class="mt-2">Por favor revise la URL e intente nuevamente.</div>

        <router-link :to="{ name: 'Home' }" class="mt-4">
          <button-blue>Ir a Home</button-blue>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import ButtonBlue from "@/components/ButtonBlue.vue";
import { defineComponent } from "vue";

export default defineComponent({
  components: {
    ButtonBlue,
  },
});
</script>
